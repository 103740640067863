import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import CustomCursor from "../CustomCursor";
// import Footer from "../Footer";
// import Header from "../Header";
import Sidebar from "../Sidebar.jsx";

export default function AuthLayout({ headerVariant }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const options = [
    {
      name: "=",
      scroll: true,
      backdrop: false,
    },
  ];
  return (
    <div style={{ height: "100vh" }}>
      {/* <Header variant={headerVariant} /> */}
      {options.map((props, idx) => (
        <Sidebar key={idx} {...props} />
      ))}
      <div className="AuthContent">
        <Outlet />
      </div>
      {/* <CustomCursor /> */}
      {/* <Footer /> */}
    </div>
  );
}
