// App.js
import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import AboutPage from "./components/Pages/AboutPage";
import BlogPage from "./components/Pages/BlogPage";
import ContactPage from "./components/Pages/ContactPage";
import ErrorPage from "./components/Pages/ErrorPage";
import Home from "./components/Pages/Home";
import ServicesPage from "./components/Pages/ServicesPage";
import PortfolioPage from "./components/Pages/PortfolioPage";
import Layout from "./components/Layout";
import FaqPage from "./components/Pages/FaqPage";
import DataTable from "./components/Pages/Stat/DataTable";
import { DataContext } from "./context/DataContext";
import HomePage from "./components/Pages/Stat/HomePage";
import Profile from "./components/Pages/auth/Profile";
import ChangePassword from "./components/Pages/auth/ChangePassword";
import BillingHistory from "./components/Pages/auth/BillingHistory";
import ManageSubscription from "./components/Pages/auth/ManageSubscription";
import AuthLayout from "./components/AuthLayout";
import PrivateRoute from "./PrivateRoute.js";
import PublicRoute from "./PublicRoute.js";
import LoginRedirect from "./components/LoginRedirect";


function App() {
  const data = useContext(DataContext) || {};

  // Configurations for MLB
  const mlbConfigs = {
    opponentstrikeout: {
      displayName: "Team Strikeouts per Game",
      data: data.strikeoutsPerGame || [],
      titleText:
        "Displays each team's average hits per game and strikeouts per game. This helps you know which pitchers are facing the best and worst lineups every day.",
    },
    opponenthit: {
      displayName: "Team Hits per Game",
      data: data.hitsPerGame || [],
      titleText:
        "Displays each team's average hits per game and strikeouts per game. This helps you know which pitchers are facing the best and worst lineups every day.",
    },
    BaseballMatchupStats: {
      displayName: "ColdHot Matchup",
      data: data.baseballMatchupStats || [],
      titleText:
        "Displays which hitters have a hot matchup vs the opposing pitcher and which hitters have a cold matchup vs the opposing pitcher. This model highlights which hitters are expected to have a good or bad game based on previous matchups against the same pitcher.",
    },
    HotStreaks_MLB: {
      displayName: "HotStreaks MLB",
      data: data.hotStreaksMLB || [],
      titleText:
        "Collects and displays player props that are currently on streaks hitting their over/under consistently in their most recent games.",
    },
    innings: {
      displayName: "1st Inning Runs",
      data: data.iningsRuns || [],
      titleText:
        "Displays each team's average runs scored in the first inning. This model helps take advantage of the YRFI/NRFI betting prop, ensuring you're aware of which teams are the best and worst in the first inning.",
    },
    mlbProjections: {
      displayName: "MLB Projections",
      data: data.playerPropsMLB || [],
      titleText:
        "The MLB projections model uses a mathematical equation to predict the outcome of player props.",
    },
  };

  // Configurations for NBA
  const nbaConfigs = {
    pointsPerGame: {
      displayName: "Opponent Points per Game",
      data: data.pointsPerGame || [],
      titleText:
        "Displays NBA team points, rebounds, and assists ALLOWED per game. These models highlight team defensive stats to ensure you're only betting on the best matchups each day.",
    },
    reboundsPerGame: {
      displayName: "Opponent Rebounds per Game",
      data: data.reboundsPerGame || [],
      titleText:
        "Displays NBA team points, rebounds, and assists ALLOWED per game. These models highlight team defensive stats to ensure you're only betting on the best matchups each day.",
    },
    assistsPerGame: {
      displayName: "Opponent Assists per Game",
      data: data.assistsPerGame || [],
      titleText:
        "Displays NBA team points, rebounds, and assists ALLOWED per game. These models highlight team defensive stats to ensure you're only betting on the best matchups each day.",
    },
    HotStreaks_NBA: {
      displayName: "HotStreaks NBA",
      data: data.hotStreaksNBA || [],
      titleText:
        "Collects and displays player props that are currently on streaks hitting their over/under consistently in their most recent games.",
    },
    nbaProjections: {
      displayName: "NBA Projections",
      data: data.playerPropsNBA || [],
      titleText:
        "The NBA projections model uses a mathematical equation to predict the outcome of player props.",
    },
  };

  // Configurations for NFL
  const nflConfigs = {
    Yard: {
      displayName: "HotStreaks NFL",
      data: data.hotStreaksNFL || [],
      titleText:
        "Collects and displays player props that are currently on streaks hitting their over/under consistently in their most recent games.",
    },
    RushingYards: {
      displayName: "Rushing Yards Allowed",
      data: data.pointsPerGame || [],
      titleText:
        "Displays each team's average pass yards and rush yards ALLOWED per game. This ensures you know which offenses are facing the best and worst defenses each week.",
    },
    PassingYards: {
      displayName: "Pass Yards Allowed",
      data: data.reboundsPerGame || [],
      titleText:
        "Displays each team's average pass yards and rush yards ALLOWED per game. This ensures you know which offenses are facing the best and worst defenses each week.",
    },
    nflProjections: {
      displayName: "NFL Projections",
      data: data.playerPropsNFL || [],
      titleText:
        "The NFL projections model uses a mathematical equation to predict the outcome of player props.",
    },
  };

  // Configurations for +EV
  const evConfigs = {
    GET_PLAYER_STATS: {
      displayName: "+EV",
      data: data.playerStats || [],
      titleText:
        "Collects and displays player prop odds from DraftKings to take advantage of PrizePicks and Underdog's set payout system. The +EV Model shows you which player props are most favored by sportsbooks so you’re always betting on the favorite when the payout odds are set.",
    },
  };

  return (
    <>
      <Routes>
        <Route path="/login-fake" element={<LoginRedirect />} />
        {/* Public Routes */}
        <Route path="/" element={<PublicRoute><Layout /></PublicRoute>}>
          <Route index element={<Home />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="pricing" element={<ServicesPage />} />
          <Route path="portfolio" element={<PortfolioPage />} />
          <Route path="login" element={<BlogPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="faq" element={<FaqPage />} />
        </Route>

        {/* Authenticated Routes */}
        <Route path="/auth" element={<AuthLayout />}>
          {/* NBA Routes */}
          <Route
            path="nba"
            element={
              <DataTable
                configs={nbaConfigs}
              />
            }
          />
          <Route
            path="nba/points-per-game"
            element={
              <DataTable
                configs={{ pointsPerGame: nbaConfigs.pointsPerGame }}
                colors={["#31df31", "yellow", "red"]}
              />
            }
          />
          <Route
            path="nba/rebounds-per-game"
            element={
              <DataTable
                configs={{ reboundsPerGame: nbaConfigs.reboundsPerGame }}
                colors={["#31df31", "yellow", "red"]}
              />
            }
          />
          <Route
            path="nba/assist-per-game"
            element={
              <DataTable
                configs={{ assistsPerGame: nbaConfigs.assistsPerGame }}
                colors={["#31df31", "yellow", "red"]}
              />
            }
          />
          <Route
            path="nba/hotstreaks-nba"
            element={
              <DataTable
                configs={{ HotStreaks_NBA: nbaConfigs.HotStreaks_NBA }}
              />
            }
          />
          <Route
            path="nba/nba-projections"
            element={
              <DataTable
                configs={{ nbaProjections: nbaConfigs.nbaProjections }}
              />
            }
          />

          {/* MLB Routes */}
          <Route
            path="mlb"
            element={
              <DataTable
                configs={mlbConfigs}
              />
            }
          />
          <Route
            path="mlb/strikeouts"
            element={
              <DataTable
                configs={{ opponentstrikeout: mlbConfigs.opponentstrikeout }}
                colors={["#31df31", "yellow", "red"]}
              />
            }
          />
          <Route
            path="mlb/hits"
            element={
              <DataTable
                configs={{ opponenthit: mlbConfigs.opponenthit }}
                colors={["#31df31", "yellow", "red"]}
              />
            }
          />
          <Route
            path="mlb/coldhot-matchup"
            element={
              <DataTable
                configs={{ BaseballMatchupStats: mlbConfigs.BaseballMatchupStats }}
              />
            }
          />
          <Route
            path="mlb/hotstreaks-mlb"
            element={
              <DataTable
                configs={{ HotStreaks_MLB: mlbConfigs.HotStreaks_MLB }}
              />
            }
          />
          <Route
            path="mlb/first-inning-runs"
            element={
              <DataTable
                configs={{ innings: mlbConfigs.innings }}
                colors={["#31df31", "yellow", "red"]}
              />
            }
          />
          <Route
            path="mlb/mlb-projections"
            element={
              <DataTable
                configs={{ mlbProjections: mlbConfigs.mlbProjections }}
              />
            }
          />

          {/* NFL Routes */}
          <Route
            path="nfl"
            element={
              <DataTable
                configs={nflConfigs}
              />
            }
          />
          <Route
            path="nfl/hotstreaks-nfl"
            element={
              <DataTable
                configs={{ Yard: nflConfigs.Yard }}
              />
            }
          />
          <Route
            path="nfl/rushing-yards-allowed"
            element={
              <DataTable
                configs={{ RushingYards: nflConfigs.RushingYards }}
                colors={["#31df31", "yellow", "red"]}
              />
            }
          />
          <Route
            path="nfl/pass-yards-allowed"
            element={
              <DataTable
                configs={{ PassingYards: nflConfigs.PassingYards }}
                colors={["#31df31", "yellow", "red"]}
              />
            }
          />
          <Route
            path="nfl/nfl-projections"
            element={
              <DataTable
                configs={{ nflProjections: nflConfigs.nflProjections }}
              />
            }
          />

          {/* +EV Routes */}
          <Route
            path="ev"
            element={
              <DataTable
                configs={evConfigs}
              />
            }
          />
          <Route
            path="ev/ev"
            element={
              <DataTable
                configs={{ GET_PLAYER_STATS: evConfigs.GET_PLAYER_STATS }}
                colors={["#31df31", "yellow", "red"]}
              />
            }
          />

          {/* User Options */}
          <Route index element={<HomePage />} />
          <Route path="profile" element={<Profile />} />
          <Route path="ChangePassword" element={<ChangePassword />} />
          <Route path="billing-history" element={<BillingHistory />} />
          <Route path="manage-subscription" element={<ManageSubscription />} />
        </Route>

        {/* Fallback Route */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
