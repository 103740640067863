// components/LoginRedirect.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginRedirect = () => {
  const navigate = useNavigate();
  localStorage.setItem('token', 'admin');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/auth');
    }
  }, [navigate]);


};

export default LoginRedirect;
